import { ALTERA_RESPOSTA_RAPIDA, ADD_MENSAGEM_RAPIDA, SET_FULL_RESPOSTA_RAPIDA, REMOVE_MENSAGEM_RAPIDA } from "./constants"

const INIT_STATE = {
    respostas_rapidas: []
};


const Resposta = (state = INIT_STATE, action) => {
    switch (action.type) {

        case ADD_MENSAGEM_RAPIDA:

            var copyRespostasRapidas = [...state.respostas_rapidas, action.payload]

            return {
                ...state,
                respostas_rapidas: copyRespostasRapidas
            }
        case ALTERA_RESPOSTA_RAPIDA:

            var copyMensagensRapidas = [...state.respostas_rapidas]

            var index_mensagem_rapida = copyMensagensRapidas.findIndex(row => row._id == action.payload._id)

            if (index_mensagem_rapida === -1) {
                return {
                    ...state
                }
            }

            copyMensagensRapidas[index_mensagem_rapida] = action.payload;

            return {
                ...state,
                respostas_rapidas: copyMensagensRapidas
            }
        case SET_FULL_RESPOSTA_RAPIDA:
            return {
                ...state,
                respostas_rapidas: action.payload
            }

        case REMOVE_MENSAGEM_RAPIDA:

            var copyMensagensRapidas = [...state.respostas_rapidas]

            copyMensagensRapidas = copyMensagensRapidas.filter(row => row._id != action.payload)

            return {
                ...state,
                respostas_rapidas: copyMensagensRapidas
            }
        default: return { ...state }
    }

}


export default Resposta;