export const CHAT_USER = "CHAT_USER";
export const ACTIVE_USER = "ACTIVE_USER";
export const FULL_USER = "FULL_USER";
export const ADD_LOGGED_USER = "ADD_LOGGED_USER";
export const CREATE_GROUP = "CREATE_GROUP";
export const ADD_FILA = "ADD_FILA"
export const FULL_FILA = "FULL_FILA"
export const ADD_TOAST = "ADD_TOAST"
export const DELETE_FILA = "DELETE_FILA"
export const FILA_NOTIFICATION = "FILA_NOTIFICATION"
export const REMOVE_USER = "REMOVE_USER"
export const SET_LOADER = "SET_LOADER"
export const ADD_NEW_MESSAGE = "ADD_NEW_MESSAGE"
export const ADD_GROUP = "ADD_GROUP"
export const SET_FULL_CONTACT = "SET_FULL_CONTACT"
export const ADD_CONTACT = "ADD_CONTACT"
export const MESSAGE_ERRO = "MESSAGE_ERRO"
export const MESSAGE_SUCCESS = "MESSAGE_SUCCESS"
export const SET_ATENDENTES = "SET_ATENDENTES"
export const REMOVE_ATENDENTE = "REMOVE_ATENDENTE"
export const SET_ATENDENTE_CONVERSA = "SET_ATENDETE_CONVERSA"
export const CONVERSA_CLOSED = "CONVERSA_CLOSED"
export const DISCONECTED_WHATSAPP = "DISCONECTED_WHATSAPP"
export const MESSAGE_DELETED = "MESSAGE_DELETED"
export const READ_MESSAGE = "READ_MESSAGE"
export const UPDATE_CONVERSA = "UPDATE_CONVERSA"
export const MESSAGE_READ_CONFIRMATION = 'MESSAGE_READ_CONFIRMATION'
export const NEXT_PAGE = 'NEXT_PAGE'
export const HAS_MORE = 'HAS_MORE'
export const INIT_PAGE= 'INIT_PAGE'
export const PAGE_FILA = "PAGE_FILA"
export const TOTAL_FILA = "TOTAL_FILA"
export const UPDATE_TAG = "UPDATE_TAG"
export const SET_FILTER = "SET_FILTER"