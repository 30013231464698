import React, { useState } from 'react';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import DialogContentText from '@mui/material/DialogContentText'
import config from "./../config";

const DialogFileSize = ({
    sizeFormated,
    limit_formated,
    percent_in_use,
    handleClose,
    open,
}) => {

    const handleSend = () => {
        handleClose()
    }

    return (
        <Dialog
            fullWidth
            maxWidth="sm"
            open={open}
        >
            <DialogTitle>Espaço insuficiente! ⚠️</DialogTitle>
            <DialogContent dividers>
                <DialogContentText id="alert-dialog-description">
                    <p>
                        Atenção, Você está utilizando <strong>{percent_in_use.toFixed(2)}%</strong> do seu espaço.<br />
                        Acesse o <a href={`${config.APP_URL}/app/files`}>Painel de Administrador</a> {" > "}  Uploads {" > "} Apagar Todos. <br />
                        Ou<br />

                        Fale com nosso suporte e contrate mais espaço de armazenamento. <br />
                        <br />

                        <strong>Espaço utilizado</strong>: {sizeFormated}<br />
                        <strong>Limite atual</strong>: {limit_formated}
                    </p>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button variant='contained' onClick={handleSend}>
                    Ok
                </Button>
            </DialogActions>
        </Dialog>
    );
}


export default DialogFileSize