import React, { useEffect, createContext } from "react"
import socketIOClient from "socket.io-client"
import { useDispatch } from 'react-redux';
import { getLoggedInUser } from "./helpers/authUtils"
import { APIClient, setAuthorization } from "./helpers/apiClient"
import config from "./config"
import { readMessage, messageReadConfirmation, messageRemoved, diconectWhatsapp, conversaClosed, setAtendenteConversa, addFila, delFila, addNewMessage, addGroup, messageError, messageSuccess, setIsAdmin } from "./redux/actions"


const api = new APIClient()

const WebSocketContext = createContext(null)

export { WebSocketContext }

export default ({ children }) => {

    var socketUsuario; // socket para receber dados apenas com os dados ligado ao _id do atendente
    var socketGeral; // socket para receber dados de forma geral a todos que estão ligados ao userId
    var socketFila; // socker para receber as filas de atendimento. será ligado ao departamento de cada usuario

    var ws = {};
    var user = getLoggedInUser()

    const dispatch = useDispatch();

    const getElementFila = (element) => {

        var now = new Date()
        var entrou_na_fila = new Date(element.data_hora_entrou_fila)
        var tempo_espera_millis = now.getTime() - entrou_na_fila.getTime()

        var nome;

        if (element.conversa.contato) {
            nome = element.conversa.contato.name
        } else if (element.conversa.lead) {
            nome = element.conversa.lead.nome
        } else {
            nome = element.conversa.pushName
        }

        if (!nome) {
            nome = element.conversa.chatId
        }

        var elemento_formatado = {
            name: nome,
            _id: element._id,
            isNew: true,
            comentario: element.comentario,
            data_hora_entrou_fila: element.data_hora_entrou_fila,
            tempo_espera: Math.floor(tempo_espera_millis / 60000)
        }

        return elemento_formatado;

    }

    if (!socketUsuario && user) {

        socketUsuario = socketIOClient(config.URL_SOCKET, {
            query: {
                id: user._id,
                origin: "chat-messages"
            }
        })

        socketUsuario.on("connect", () => {
            console.log(`[CLIENT_SOCKET_IO - USUARIO - CONECTADO] Connected:`, socketUsuario.connected);
        })

        socketUsuario.on("mensagem_chat_atendente", data => {
            if (!data.conversa.isFechado && !data.conversa.isAvaliacao) {
                dispatch(addNewMessage({ ...data, currentAtendenteId: user._id }))
            }
        })

        socketUsuario.on("mensagem_chat_atendente_admin", data => {
            if (!data.conversa.isFechado && !data.conversa.isAvaliacao) {
                dispatch(addNewMessage({ ...data, currentAtendenteId: user._id, isAdmin: true }))
            }
        })

        socketUsuario.on("del_fila_atendimento", (element) => {
            dispatch(delFila(element))
        })

        socketUsuario.on("add_fila_atendimento", (element) => {
            var elemento_formatado = getElementFila(element)
            dispatch(addFila(elemento_formatado))
        })

        ws.socketUsuario = socketUsuario

    }

    if (!socketGeral && user) {

        socketGeral = socketIOClient(config.URL_SOCKET, {
            query: {
                id: user.userId,
                origin: "chat-messages"
            }
        })

        socketGeral.on("connect", () => {
            console.log(`[CLIENT_SOCKET_IO - GERAL - CONECTADO] Connected:`, socketGeral.connected);
        })

        socketGeral.on("message_group", data => {
            if (data.atendente != user._id) {
                dispatch(addGroup(data))
            }
        })

        socketGeral.on("message_error", data => {
            dispatch(messageError(data))
        })

        socketGeral.on("message_success", data => {
            dispatch(messageSuccess(data))
        })

        socketGeral.on("message_read", data => {
            dispatch(messageReadConfirmation(data))
        })

        socketGeral.on("message_removed", data => {
            dispatch(messageRemoved(data))
        })

        socketGeral.on("conversa_closed", data => {
            dispatch(conversaClosed(data.conversa))
        })

        socketGeral.on("whastappSession.disconected", data => {
            dispatch(diconectWhatsapp(data))
        })

        socketGeral.on("read_messages", data => {
            dispatch(readMessage(data))
        })

        setAuthorization(user.token)

        api.get("/atendente/isAdmin")
            .then(success => {

                if (success.isAdmin) {
                    socketGeral.on("mensagem_chat_atendente_admin", data => {
                        // verifica se a covnersa está fechada ou esperando por avaliacao
                        if (!data.conversa.isFechado && !data.conversa.isAvaliacao) {

                            //verifica se tem atendente e se o atendente é diferente do usuário em questão
                            if (!data.atendente || user._id != data.atendente._id) {

                                // verifica se a conversa é equipe, se for equipe, verifica se está relacionada ao usuário
                                if (data.conversa.isEquipe && (data.atendente._id == user._id || data.chatId == user._id)) {
                                    dispatch(addNewMessage({ ...data, currentAtendenteId: user._id, isAdmin: true }))

                                    // não é equipe então adiciona a conversa
                                } else if (!data.conversa.isEquipe) {
                                    dispatch(addNewMessage({ ...data, currentAtendenteId: user._id, isAdmin: true }))
                                }
                            }
                        }
                    })
                } else {
                    console.warn("Você não é admin!")
                }

                socketGeral.on("admin_assumiu_conversa", data => {
                    if (user._id != data.atendente._id) {
                        dispatch(setAtendenteConversa({ ...data, currentAtendenteId: user._id, isAdmin: success.isAdmin }))
                    }
                })

                dispatch(setIsAdmin(success.isAdmin))
            })
            .catch(err => console.log(err))



        ws.socketGeral = socketGeral

    }

    if (!socketFila && user) {

        const ids_sockets = [...user.departamento]

        ids_sockets.map(departamento => {
            const io = socketIOClient(config.URL_SOCKET, {
                query: {
                    id: departamento._id,
                    origin: "chat_fila_atendimento"
                }
            })


            io.on("connect", () => {
                console.log(`[CLIENT_SOCKET_IO - DEPARTAMENTO - CONECTADO] Connected: ${departamento.nome}`, io.connected);
            })


            io.on("del_fila_atendimento", (element) => {
                dispatch(delFila(element))
            })

            io.on("add_fila_atendimento", (element) => {
                var elemento_formatado = getElementFila(element)
                dispatch(addFila(elemento_formatado))
            })
        })

        socketFila = true;
    }

    return (
        <WebSocketContext.Provider value={ws}>
            {children}
        </WebSocketContext.Provider>
    )
};