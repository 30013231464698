import React, { useState } from 'react';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import DialogContentText from '@mui/material/DialogContentText'
import { CircularProgress } from '@mui/material';

const DialogVersao = ({
    versao,
    handleAtualizar,
    open,
}) => {
    const [loader, setLoader] = useState(false)

    const handleSend = () => {
        setLoader(true)
        handleAtualizar()
    }

    return (
        <Dialog
            fullWidth
            maxWidth="sm"
            open={open}
        >
            <DialogTitle>Atualização 🔔</DialogTitle>
            <DialogContent dividers>
                <DialogContentText id="alert-dialog-description">
                    Para continuar usando o sistema atualize para a nova versão!<br />
                    Sua versão: <b>{window.getVersion().versao || '0.0.9'}</b> Atualize para: <b>{versao}</b><br />
                    Não deu certo? Tente Limpar o cache do seu navegador. <a href="https://support.google.com/accounts/answer/32050?hl=pt-BR&co=GENIE.Platform%3DDesktop&oco=1">Saiba mais...</a>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                {loader ?
                    <CircularProgress />
                    : <Button variant='contained' onClick={handleSend}>
                        Atualizar
                    </Button>}
            </DialogActions>
        </Dialog>
    );
}


export default DialogVersao