import { combineReducers } from 'redux';

import Auth from './auth/reducers';
import Chat from './chat/reducers';
import Layout from './layout/reducer';
import Resposta from './Respostas/reducer'
import Historic from './historic/reducers'
import Backdrop from './backdrop/reducers'

export default combineReducers({
    Auth,
    Chat,
    Layout,
    Resposta,
    Historic,
    Backdrop
});