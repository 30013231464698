import React, { useState } from "react"
import { Modal, ModalBody } from "reactstrap";
import {
    InputLabel,
    Select,
    MenuItem,
} from "@mui/material"

const SelectAtendente = ({ Callmodal, toggleCallModal, atendentes, setSelect }) => {

    const [atendente, setAtendente] = useState()

    const selecAtendente = (id) => {
        if (!id) return
        const atendente = atendentes.filter(row => row._id == id)[0]
        setSelect(atendente)
    }

    return (<React.Fragment>
        < Modal tabIndex="-1" isOpen={Callmodal} toggle={toggleCallModal} centered>
            <ModalBody>
                <div className="text-center p-4">
                    <h5 className="text-truncate">Ver o Dashboard como: </h5>
                    <div className="mb-4">
                        <InputLabel id="demo-simple-select-label">
                            Selecione o atendente:
                        </InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            variant="standard"
                            style={{ minWidth: 200 }}
                            onChange={(e) => setAtendente(e.target.value)}
                        >
                            {atendentes.map((row, key) => (
                                <MenuItem key={row._id} label={row.nome} value={row._id}>
                                    {row.nome}
                                </MenuItem>
                            ))}
                        </Select>
                    </div>
                    <div className="mt-5">
                        <ul className="list-inline mb-1">
                            <li className="list-inline-item px-2 me-2 ms-0">
                                <button type="button" className="btn btn-danger avatar-sm rounded-circle" onClick={toggleCallModal}>
                                    <span className="avatar-title bg-transparent font-size-20">
                                        <i className="ri-close-fill"></i>
                                    </span>
                                </button>
                            </li>
                            <li className="list-inline-item px-2">
                                <button type="button" onClick={() => selecAtendente(atendente)} className="btn btn-success avatar-sm rounded-circle">
                                    <span className="avatar-title bg-transparent font-size-20">
                                        <i className="ri-check-line"></i>
                                    </span>
                                </button>
                            </li>
                        </ul>
                    </div>
                </div>
            </ModalBody>
        </Modal >
    </React.Fragment>
    )
}

export default SelectAtendente