import {
    LOGIN_USER,
    DISCONECTED_WHATSAPP,
    LOGIN_USER_SUCCESS,
    LOGOUT_USER,
    REGISTER_USER,
    REGISTER_USER_SUCCESS,
    FORGET_PASSWORD,
    FORGET_PASSWORD_SUCCESS,
    API_FAILED,
    UPDATE_STATUS,
    IS_ADMIN
} from './constants';

import { toast } from "react-toastify";

import { getLoggedInUser, setFilter, getLoggedInUserAdmin, setLoggedInUser, getDeviceId } from '../../helpers/authUtils';

const INIT_STATE = {
    user: getLoggedInUser(),
    userAdmin: getLoggedInUserAdmin(),
    device_id: getDeviceId(),
    isAdmin: false,
    loading: false
};


const Auth = (state = INIT_STATE, action) => {
    switch (action.type) {
        case LOGIN_USER:
            return { ...state, loading: true };
        case LOGIN_USER_SUCCESS:
            return { ...state, user: action.payload, loading: false, error: null };

        case REGISTER_USER:
            return { ...state, loading: true };
        case REGISTER_USER_SUCCESS:
            return { ...state, user: action.payload, loading: false, error: null };

        case LOGOUT_USER:
            setFilter({})
            return { ...state, user: null };
        case UPDATE_STATUS:
            setLoggedInUser({ ...state.user, status: action.payload })
            state.user.status = action.payload
            return state
        case FORGET_PASSWORD:
            return { ...state, loading: true };
        case FORGET_PASSWORD_SUCCESS:
            return { ...state, passwordResetStatus: action.payload, loading: false, error: null };

        case API_FAILED:
            return { ...state, loading: false, error: action.payload };

        case IS_ADMIN:
            return {
                ...state,
                isAdmin: action.payload
            }
        case DISCONECTED_WHATSAPP:
            toast.error("Whatsapp desconectado!")
            return {
                ...state
            }

        default: return { ...state };
    }
}

export default Auth;