import {
    DISCONECTED_WHATSAPP,
    LOGIN_USER,
    LOGIN_USER_SUCCESS,
    LOGOUT_USER,
    REGISTER_USER,
    REGISTER_USER_SUCCESS,
    FORGET_PASSWORD,
    FORGET_PASSWORD_SUCCESS,
    API_FAILED,
    IS_ADMIN,
    UPDATE_STATUS
} from './constants';


export const loginUser = (username, password, history, isVerComo, user, isLogout) => ({
    type: LOGIN_USER,
    payload: { username, password, history, isVerComo, user, isLogout }
});

export const updateStatus = (status) => ({
    type: UPDATE_STATUS,
    payload: status
})

export const loginUserSuccess = (user) => ({
    type: LOGIN_USER_SUCCESS,
    payload: user
});

export const registerUser = (user) => ({
    type: REGISTER_USER,
    payload: { user }
});

export const registerUserSuccess = (user) => ({
    type: REGISTER_USER_SUCCESS,
    payload: user
});

export const logoutUser = (history) => ({
    type: LOGOUT_USER,
    payload: { history }
});

export const forgetPassword = (email) => ({
    type: FORGET_PASSWORD,
    payload: { email }
});

export const forgetPasswordSuccess = (passwordResetStatus) => ({
    type: FORGET_PASSWORD_SUCCESS,
    payload: passwordResetStatus
});

export const apiError = (error) => ({
    type: API_FAILED,
    payload: error
});


export const setIsAdmin = (isAdmin) => ({
    type: IS_ADMIN,
    payload: isAdmin
})

export const diconectWhatsapp = (data) => ({
    type: DISCONECTED_WHATSAPP,
    payload: data
})