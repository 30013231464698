import { SET_LOADER } from "./constants"

const INIT_STATE = {
    loader : false
}


const Loader = (state = INIT_STATE, action) => {
    switch(action.type){
        case SET_LOADER:
            return {
                ...state,
                loader: action.payload,
            }
        default: return {...state}
    }
}


export default Loader;