import React from 'react';
import Routes from './routes/';
import { Helmet } from "react-helmet"
import config from "./config_empresa"
//Import Scss
import "./assets/scss/themes.scss";

// import PullToRefreshComponent from './components/PullToRequest';
function capitalize(string) {
  return string.charAt(0).toUpperCase() + string.substr(1);
}

function App() {
  return (
    <>
      <Helmet>
        <title>{capitalize(config.EMPRESA)} - Chat</title>
      </Helmet>
      {/* <PullToRefreshComponent Component={Routes} /> */}
      <Routes />
    </>
  );
}

export default App;
