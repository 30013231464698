import {
    SET_FILTER, UPDATE_TAG,  TOTAL_FILA, INIT_PAGE, NEXT_PAGE, MESSAGE_READ_CONFIRMATION, UPDATE_CONVERSA, READ_MESSAGE, ADD_MENSAGEM_RAPIDA, MESSAGE_DELETED, CONVERSA_CLOSED, SET_ATENDENTE_CONVERSA, REMOVE_ATENDENTE, MESSAGE_SUCCESS, MESSAGE_ERRO, ADD_CONTACT, CHAT_USER, ACTIVE_USER, FULL_USER, ADD_LOGGED_USER, CREATE_GROUP, ADD_FILA, FULL_FILA, ADD_TOAST, DELETE_FILA, FILA_NOTIFICATION, REMOVE_USER, SET_LOADER, ADD_NEW_MESSAGE, ADD_GROUP, SET_FULL_CONTACT, SET_ATENDENTES, HAS_MORE, PAGE_FILA
} from './constants';


export const chatUser = () => ({
    type: CHAT_USER
});

export const nextPage = () => ({
    type: NEXT_PAGE
})

export const initPage = () => ({
    type: INIT_PAGE
})

export const hasMore = (isHasMore) => ({
    type: HAS_MORE,
    payload: isHasMore
})

export const activeUser = (userId) => ({
    type: ACTIVE_USER,
    payload: userId
});

export const setFullUser = (fullUser) => ({
    type: FULL_USER,
    payload: fullUser
});

export const addLoggedinUser = (userData) => ({
    type: ADD_LOGGED_USER,
    payload: userData
});

export const createGroup = (groupData) => ({
    type: CREATE_GROUP,
    payload: groupData
})

export const addFila = (data) => ({
    type: ADD_FILA,
    payload: data
})

export const setTotalFila = (total) => ({
    type: TOTAL_FILA,
    payload: total
})

export const setFullFila = (fullFila, total) => ({
    type: FULL_FILA,
    payload: { fullFila, total }
})

export const addToast = () => ({
    type: ADD_TOAST,
    payload: null
})

export const delFila = (id) => ({
    type: DELETE_FILA,
    payload: id
})

export const filaNotification = () => ({
    type: FILA_NOTIFICATION
})

export const removeUser = (id) => ({
    type: REMOVE_USER,
    payload: id
})

export const setLoader = () => ({
    type: SET_LOADER
})

export const addNewMessage = (data) => ({
    type: ADD_NEW_MESSAGE,
    payload: data
})

export const addGroup = (data) => ({
    type: ADD_GROUP,
    payload: data
})

export const setFullContacts = (data) => ({
    type: SET_FULL_CONTACT,
    payload: data
})

export const addContact = (data) => ({
    type: ADD_CONTACT,
    payload: data
})

export const messageError = (data) => ({
    type: MESSAGE_ERRO,
    payload: data
})

export const messageSuccess = (data) => ({
    type: MESSAGE_SUCCESS,
    payload: data
})

export const messageReadConfirmation = (data) => ({
    type: MESSAGE_READ_CONFIRMATION,
    payload: data
})

export const setAtendentes = (data) => ({
    type: SET_ATENDENTES,
    payload: data
})

export const setNullAtendente = (data) => ({
    type: REMOVE_ATENDENTE,
    payload: data
})

export const setAtendenteConversa = (data) => ({
    type: SET_ATENDENTE_CONVERSA,
    payload: data
})

export const conversaClosed = (conversa) => ({
    type: CONVERSA_CLOSED,
    payload: conversa
})

export const messageRemoved = (data) => ({
    type: MESSAGE_DELETED,
    payload: data
})

export const readMessage = (data) => ({
    type: READ_MESSAGE,
    payload: data
})

export const updateConversa = (data) => ({
    type: UPDATE_CONVERSA,
    payload: data
})

export const updateTags = (data) => ({
    type: UPDATE_TAG,
    payload: data
})

export const setPageFila = (page) => ({
    type: PAGE_FILA,
    payload: page
})

export const setFilter = (filter) => ({
    type: SET_FILTER,
    payload: filter
})