import React, { Suspense, useEffect, useState } from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
//import routes
import { authProtectedRoutes, publicRoutes } from './routes';

//import layouts
import NonAuthLayout from "../layouts/NonAuth";
import AuthLayout from "../layouts/AuthLayout/";
import { useSelector } from "react-redux"
import { useDispatch } from 'react-redux';
import { loginUserSuccess, logoutUser, loginUser } from '../redux/actions';
import DialogAtualizacao from '../components/DialogUpdate'
import DialogFilesSize from '../components/DialogFileSize'
import DialogNotification from '../components/DialogPermissions'
import { Button } from "reactstrap"
import { toast } from 'react-toastify';
import { getDeviceId, getLoggedInUser, getLoggedInUserAdmin, isUserAuthenticated } from "../helpers/authUtils"
import { APIClient, setAuthorization } from "../helpers/apiClient"
import {
    requestPermission,
    getPermissionNotifications,
    getSubscribePushNotification,
    unsubscribePushNotification,
    subscribeUserToPush,
    checkBannerPermissions,
    setBannerPermission,
    getPermissionSoundNotification,
    handleChangeSoundNotification,
    subscribePushNotificationMobile
} from '../helpers/Notification'
import { isAndroid, isIOS } from 'react-device-detect';
import { UNAUTHORIZED } from '../helpers/Constants';

const api = new APIClient();

// handle auth and authorization
const AppRoute = ({ component: Component, layout: Layout, isAuthProtected, ...rest }) => {
    return <Route {...rest} render={props => {

        if (isAuthProtected && !isUserAuthenticated()) {
            return (
                <Redirect to={{ pathname: "/login", state: { from: props.location } }} />
            );
        }
        // authorised so return component
        return <Layout><Component {...props} /></Layout>
    }} />
}
/**
 * Main Route component
 */
const Routes = (props) => {

    const dispatch = useDispatch()
    const [show, setShow] = useState(false)
    const [showFileStatus, setShowFileStatus] = useState(false)
    const [versao, setVersao] = useState()

    const [percentInUse, setPercentInUse] = useState(0)
    const [sizeFormated, setSizeFormated] = useState(0)
    const [limitSize, setLimitSize] = useState(0)
    const [userAdmin, setUserAdmin] = useState()
    const [user, setUser] = useState()
    const [device_id, setDeviceId] = useState()
    // permissoes
    const [permissionNotifications, setPermissionNotification] = useState(false)
    const [subscriptionPushNotification, setPushNotification] = useState(false)
    const [soundNotification, setSoundNotification] = useState(false)
    const [modalPermission, setModalPermission] = useState(false)

    useEffect(() => {
        // verifica se o token é valido, caso o token não seja válido
        const user = getLoggedInUser()
        console.log(user)
        const userAdmin = getLoggedInUserAdmin()
        const device_id = getDeviceId()

        if (!user || !user.token) {
            localStorage.removeItem("authUser")
            // window.location.href = '/login'
            dispatch(logoutUser())
            return false
        }

        setAuthorization(user.token)
        setUserAdmin(userAdmin)
        setUser(user)
        setDeviceId(device_id)

        api.get("atendente")
            .then(success => {
                dispatch(loginUserSuccess(success))
            })
            .catch(err => {
                if (err == UNAUTHORIZED) {
                    localStorage.removeItem("authUser")
                    if (!window.location.pathname.includes("/login")) {
                        window.location.href = '/login'
                    }
                    dispatch(logoutUser())
                }
            })

        const pushActivated = localStorage.getItem('push') || "enable"
        if (pushActivated == "enable" && (isIOS || isAndroid)) {
            subscribePushNotificationMobile(user._id, device_id, user.token, user.userId)
        }

    }, [])

    useEffect(() => {
        api.get("/version/chat")
            .then(async success => {
                if (success.versao != window.getVersion().versao) {
                    setVersao(success.versao)
                    await clearCache()
                    setShow(true)
                }
            })
            .catch(err => err)
    }, [])

    useEffect(() => {
        api.get("atendente/fileStatus")
            .then(success => {
                if (success.percent_in_use > 93) {
                    setLimitSize(success.limit_formated)
                    setSizeFormated(success.sizeFormated)
                    setPercentInUse(success.percent_in_use)
                    setShowFileStatus(true)
                }
            })
            .catch(err => {
                console.log(err)
            })
    }, [])

    useEffect(() => {
        checkPermissions()
        checkSoundNotification()
        checkPushNotification()
        checkModalPermission()
    }, [])


    const checkPermissions = async () => {
        const permission_notification = await getPermissionNotifications()
        if (permission_notification === "granted") setPermissionNotification(true)
    }

    const checkSoundNotification = () => {
        const permission = getPermissionSoundNotification()
        if (permission == "granted") setSoundNotification(true)
        else setSoundNotification(false)
    }

    const checkPushNotification = async () => {
        const subscription = await getSubscribePushNotification()
        console.log("subscription:", subscription)
        setPushNotification(subscription)
    }

    const checkModalPermission = async () => {
        const permission = checkBannerPermissions()
        setModalPermission(permission)
    }

    const handleChangePushNotification = async () => {

        console.log("push notification: ", subscriptionPushNotification)
        try {
            // se existe subscripiton, desativa
            if (subscriptionPushNotification) {
                const response = await unsubscribePushNotification(user, device_id)
                console.log("Push notificaiton: ", response)
                setPushNotification(false)
                toast.warn("Push notification desativado!")
            } else {
                // se não existe, faz uma nova subscription
                const response = await subscribeUserToPush(user, device_id)
                if (response.error) return toast.error("Erro ao ativar push notification!")
                setPushNotification(true)
                toast.success("Push Notification Ativado!")
            }
        } catch (e) {
            console.log(e)
            toast.error("Push Notification não habilitado!")
        }
    }

    const handleChangePermissionNotification = async () => {
        if (!permissionNotifications) {
            const permission_notification = await requestPermission()
            if (permission_notification === "granted") {
                setPermissionNotification(true)
                toast.success("Permissão concedida!")
            } else {
                toast.error("Permissão não concedida!")
            }
        } else {
            toast.warning("Desative as notificaçõeas através da configuração do seu dispositivo!", { autoClose: false })
        }
    }

    const changeSoundNotification = () => {
        const permission = handleChangeSoundNotification()
        if (permission == "granted") {
            setSoundNotification(true)
            toast.success("Notificação sonora ativada!")
        }
        else {
            setSoundNotification(false)
            toast.warn("Notificação sonora desativada!")
        }
    }

    const handleChangeModalPermission = () => {
        setBannerPermission()
        setModalPermission(false)
        window.location.reload(true)
    }

    const clearCache = async () => {
        try {
            var names = await caches.keys()

            for (let name of names) {
                console.log(name)
                try {
                    await caches.delete(name);
                } catch (e) {
                    console.log(e)
                }
            }
        } catch (e) {
            console.log(e)
        }
    }

    const handleAtualizar = async () => {
        window.location.reload(true)
    }

    const handleSairVerComo = () => {
        const user = getLoggedInUserAdmin()
        dispatch(loginUser(null, null, null, true, user, true))
    }

    return (
        // rendering the router with layout
        <BrowserRouter>
            <React.Fragment>
                {userAdmin &&
                    <div class="bg-info clearfix d-flex justify-content-center">
                        <span class="pt-2" style={{ marginLeft: '10px', marginRight: '10px', color: 'white' }}>
                            Você está acessando como: <b>{user.nome}</b>
                        </span>
                        <Button type="button" color="info" className="font-size-14 btn-lg waves-effect float-end" onClick={handleSairVerComo}>
                            Sair
                        </Button>
                    </div>
                }
                <Suspense fallback={<div></div>} >
                    <Switch>
                        {/* public routes */}
                        {publicRoutes.map((route, idx) =>
                            <AppRoute path={route.path} layout={NonAuthLayout} component={route.component}
                                key={idx} isAuthProtected={false} />
                        )}

                        {/* private/auth protected routes */}
                        {authProtectedRoutes.map((route, idx) =>
                            <AppRoute path={route.path} layout={AuthLayout} component={route.component}
                                key={idx} isAuthProtected={true} />
                        )}
                    </Switch>
                </Suspense>
                <DialogAtualizacao
                    versao={versao}
                    handleAtualizar={handleAtualizar}
                    open={show}
                />

                <DialogFilesSize
                    limit_formated={limitSize}
                    sizeFormated={sizeFormated}
                    percent_in_use={percentInUse}
                    open={showFileStatus}
                    handleClose={() => setShowFileStatus(false)}
                />

                <DialogNotification
                    pushNotification={subscriptionPushNotification}
                    notifications={permissionNotifications}
                    soundNotification={soundNotification}
                    handleChangeNotification={handleChangePermissionNotification}
                    handleChangePushNotification={handleChangePushNotification}
                    handleChangeSoundNotification={changeSoundNotification}
                    handleChangeModal={handleChangeModalPermission}
                    open={user && modalPermission && (!subscriptionPushNotification || !permissionNotifications || !soundNotification) && !isIOS && !isAndroid}
                />

                {userAdmin &&
                    <div class="bg-info clearfix d-flex justify-content-center">
                        <span class="pt-2" style={{ marginLeft: '10px', marginRight: '10px', color: 'white' }}>
                            Você está acessando como: <b>{user.nome}</b>
                        </span>
                        <Button type="button" color="info" className="font-size-14 btn-lg waves-effect float-end" onClick={handleSairVerComo}>
                            Sair
                        </Button>
                    </div>
                }
            </React.Fragment>
        </BrowserRouter>
    );
}

export default Routes;