import { ACTIVE_HISTORICO_CONVERSA, ACTIVE_MESSAGES, SET_OPTION_PAGINATE, SET_NULL_HISTORIC } from "./constants"

const INIT_STATE = {
    active_conversa: '',
    active_messages: [],
    optionPaginate: {
        nextPage: 1,
        sum: 10
    }
}


const Historic = (state = INIT_STATE, action) => {
    switch (action.type) {
        case ACTIVE_HISTORICO_CONVERSA:
            return {
                optionPaginate: action.payload._id == state.active_conversa._id ? state.optionPaginate : {
                    nextPage: 1,
                    sum: 10
                },
                active_messages: action.payload._id == state.active_conversa._id ? state.active_messages : [],
                active_conversa: action.payload
            }
        case SET_NULL_HISTORIC:
            return {
                active_conversa: '',
                active_messages: [],
                optionPaginate: {
                    nextPage: 1,
                    sum: 10
                }
            }
        case ACTIVE_MESSAGES:
            return {
                ...state,
                active_messages: action.payload
            }
        case SET_OPTION_PAGINATE:
            return {
                ...state,
                optionPaginate: action.payload
            }
        default: return { ...state };
    }
}

export default Historic