import React, { useState } from 'react';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import DialogContentText from '@mui/material/DialogContentText'
import { Box, Typography} from '@mui/material'
import { BiNotification } from "react-icons/bi";
import Switch from '../components/Switch';
import FormGroup from '@mui/material/FormGroup';

import { isAndroid, isIOS, isMacOs, isSafari } from "react-device-detect"

const DialogVersao = ({
    pushNotification,
    notifications,
    soundNotification,
    handleChangePushNotification,
    handleChangeSoundNotification,
    handleChangeNotification,
    handleChangeModal,
    open,
}) => {

    const handleClose = () => {
        handleChangeModal()
    }

    return (
        <Dialog
            fullWidth
            maxWidth="sm"
            open={open}
        >
            <DialogTitle>Permissões Necessárias {<BiNotification />}</DialogTitle>
            <DialogContent dividers>
                <DialogContentText id="alert-dialog-description">
                    Para oferecer a melhor experiência possível, precisamos de algumas permissões:
                </DialogContentText>
                <Box
                    noValidate
                    component="form"
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        m: 'auto',
                        width: 'fit-content',
                    }}
                >
                    <FormGroup>
                        { !isIOS && <Switch defaultChecked={true} label={"Notificações"} value={notifications} onChange={handleChangeNotification}/>}
                        { !((isMacOs && isSafari) || isIOS || isAndroid) && <Switch defaultChecked={true} label={"Push Notification"} value={pushNotification} onChange={handleChangePushNotification}/>}
                        <Switch defaultChecked={true} label={"Sons"} value={soundNotification} onChange={handleChangeSoundNotification}/>
                    </FormGroup>
                </Box>
            <Typography color='darkgray' variant='caption'>Você pode mudar essas configurações em Perfil -> Permissões</Typography>
            </DialogContent>
            <DialogActions>
                <Button variant='text' onClick={handleClose}>
                    Ok
                </Button>
            </DialogActions>
        </Dialog>
    );
}


export default DialogVersao